$(function () {
    $('#signUpRegistrationForm').on('submit', function (e) {
        e.preventDefault();
        $.ajax({
                method: "POST",
                url: $('#signUpRegistrationForm').attr('action'),
                data: {
                    username: $('#register_username').val(),
                    region: $('#register_region').val(),
                    tel: $('#register_tel').val(),
                    email: $('#register_email').val(),
                    password: $('#register_password').val()
                }
            })
            .done(function (response) {
                if (response == 'success') {
                    $('#registration-success').modal('show');
                } else {
                    $('#registration-fail').modal('show');
                }
            })
            .fail(function (xhr, status, error) {
                if (xhr.responseText == 'username-duplicate') {
                    $('#registration-username-duplicate').modal('show');
                    return;
                }
                if (xhr.responseText == 'email-duplicate') {
                    $('#registration-email-duplicate').modal('show');
                    return;
                }
                $('#registration-fail').modal('show');
            })
            .always(function () {
                $('#signUpRegistration').modal('hide');
            });
    });

    $('#forgotPasswordForm').on('submit', function (e) {
        e.preventDefault();
        $.ajax({
                method: "POST",
                url: $('#forgotPasswordForm').attr('action'),
                data: {
                    email: $('#forgot-password-email').val()
                }
            })
            .done(function (response) {
                $('#forgot-password-success').modal('show');
            })
            .fail(function (xhr, status, error) {
                if (xhr.responseText == 'not-found') {
                    $('#forgot-password-not-found').modal('show');
                    return;
                }
                $('#forgot-password-fail').modal('show');
            })
            .always(function () {
                $('#forgotPassword').modal('hide');
            });
    });
});